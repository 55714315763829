import React, { FunctionComponent, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faLinkedinIn, faTwitter, faYoutube, faInstagram} from '@fortawesome/free-brands-svg-icons'




const FloatingSocialIcons: FunctionComponent = () => {

    const [isVisible,setIsVisible] = useState("md:flex");
    const handleIntersection = (entries)=> {
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                setIsVisible("");
            }
            else {
                setIsVisible("md:flex");
            }
         });
    }

    useEffect(()=> {
        const footer = document.getElementById('footer');
        const observer = new IntersectionObserver(handleIntersection,{threshold: .5});
        if(footer) {
            observer.observe(footer)
        }

        return ()=> {
            if(footer) {
                observer.unobserve(footer)
            }
        }
    },[]);

    return (
        <div className={`hidden ${isVisible} fixed z-30 flex-col left-5 top-0 bottom-0 justify-center items-center`}>
            <div className="flex flex-col   h-64 text-gray-500 justify-between text-3xl">
                <div className="rounded-full h-12 w-12 border-solid bg-transparent  border-gray-500 border-2 flex justify-center items-center cursor-pointer transform hover:scale-110">
                    <a href="https://www.linkedin.com/company/bytestrone/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} className="text-3xl" /></a>
                </div>
                <div className="rounded-full h-12 w-12 border-solid bg-transparent  border-gray-500 border-2 flex justify-center items-center cursor-pointer transform hover:scale-110">
                    <a href="https://www.facebook.com/bytestrone" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookSquare}  className="text-3xl"/></a>
                </div>
                <div className="rounded-full h-12 w-12 border-solid bg-transparent  border-gray-500 border-2 flex justify-center items-center cursor-pointer transform hover:scale-110">
                    <a href="https://twitter.com/bytestrone" rel="noreferrer" target="_blank"> <FontAwesomeIcon icon={faTwitter}  className="text-3xl"/></a>
                </div>
                <div className="rounded-full h-12 w-12 border-solid bg-transparent  border-gray-500 border-2 flex justify-center items-center cursor-pointer transform hover:scale-110">
                    <a href="https://www.youtube.com/channel/UClezDvUFUMTz6h0efOmdrMw" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faYoutube}  className="text-3xl"/></a>
                </div>
                <div className="rounded-full h-12 w-12 border-solid bg-transparent  border-gray-500 border-2 flex justify-center items-center cursor-pointer transform hover:scale-110">
                    <a href="https://www.instagram.com/bytestrone" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram}  className="text-3xl"/></a>
                </div>
            </div>
        </div>
    );
}

export default FloatingSocialIcons;