import React, { FunctionComponent } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { useIntl } from "gatsby-plugin-react-intl"
//import LanguagePicker from './languagePicker'


const NavBarItems: FunctionComponent<{onNavbarClick?:Function,navItems:[],location:Location}> = ({onNavbarClick,navItems,location})=> {
     const intl = useIntl();
   
    const navbarItems = navItems.map((item:{title:string,link:string,isSelected:Boolean,hasChildren:Boolean,childrens:{title:string,link:string}})=>{
    const localeLink = intl.locale=='en'? item.link:`/${intl.locale}${item.link}`;        
         return (<NavBarItem key={item.title} to={localeLink} isSelected={item.isSelected} isActive={location.pathname.startsWith(localeLink)} title={intl.formatMessage({id:`navbar.${item.title}`})} onNavbarClick={onNavbarClick}/>);
    });

    // return <>{navbarItems}<LanguagePicker/></>;
    return <>{navbarItems}</>;
};


const NavBarItem: React.FC<{ title: string, to: string,isActive:Boolean,isSelected?:Boolean, onNavbarClick?:Function }> = ({ title, to, isActive,isSelected, onNavbarClick }) => {
    const activeClass = isActive?" font-semibold text-bytestrone-blue":"";
    return (
        <AniLink swipe duration={2} to={to}
            //partiallyActive={true}
            onClick={()=>{
                if(onNavbarClick){
                    onNavbarClick();
                }
            }}
            className={`lg:inline-flex  lg:w-auto w-full py-2 mx-1 lg:py-0  text-sm lg:text-sm  items-center justify-center whitespace-nowrap  ${activeClass}`}>
            {!isSelected?<span className="menu-hover font-headline w-full">{title}</span>:<span className="bg-bytestrone-red text-white rounded-full py-2 px-10">{title}</span>}
        </AniLink>
    )
};



export {NavBarItems,NavBarItem};

