import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faTwitter,
  faInstagram,
  faYoutube,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import useScrollPosition from "@react-hook/window-scroll";
import {
  faArrowUp,
  faAt,
  //faPhone
} from "@fortawesome/free-solid-svg-icons";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

const Footer: FunctionComponent = () => {
  return (
    <div
      id="footer"
      className=" bg-gray-200 text-bytestrone-blue py-8  relative"
    >
      <div className="xl:px-10 pb-12 md:pb-16 lg:px-5 md:px-2 sm:px-1 px-5 pt-5">
        <div className="flex flex-col sm:flex-row  justify-start space-y-3 md:space-y-0">
          <div className="w-full sm:w-3/6 flex flex-col space-y-3">
            <AniLink className=" font-bold font-bytestrone" to="/services">
              SERVICES
            </AniLink>
            <AniLink className=" font-bold font-bytestrone" to="/careers">
              CAREERS
            </AniLink>
            <AniLink className=" font-bold font-bytestrone" to="/our-point-of-view">
              OUR POINT OF VIEW
            </AniLink>
            <AniLink className=" font-bold font-bytestrone" to="/engineering-excellence">
              ENGINEERING EXCELLENCE
            </AniLink>
            <div className="w-full  flex flex-col space-y-3 px-4">
              <Link className="font-bytestrone" to="/engineering-excellence#pragmatic-solution">
                  Pragmatic One-Stop Solution
              </Link>
              <Link className="font-bytestrone" to="/engineering-excellence#precision-team">
                Precision Crafted Team
              </Link>
              <Link className="font-bytestrone" to="/engineering-excellence#unique-characteristics">
                  Unique Characteristics
              </Link>
              <Link className="font-bytestrone" to="/engineering-excellence#delivering-excellence">
                Delivering Engineering Excellence
              </Link>
              <Link className="font-bytestrone" to="/engineering-excellence#agility-beyond">
                Agility Beyond Engineering
              </Link>
            </div>
          </div>
          <div className="w-full sm:w-3/6 flex flex-col space-y-3">
            <AniLink className="font-bold font-bytestrone" to="/about">
              ABOUT US
            </AniLink>
            <div className="w-full  flex flex-col space-y-3 px-4 ">
              <Link className="font-bytestrone" to="/about#purpose">
                Our Purpose
              </Link>
              <Link className="font-bytestrone" to="/about#vision">
                Our Vision
              </Link>
              <Link className="font-bytestrone" to="/about#values">
                Our Values
              </Link>
              <Link className="font-bytestrone" to="/about#executiveleadership">
                Executive Leadership Team
              </Link>
              <Link className="font-bytestrone" to="/about#leadership">
                Leadership Team
              </Link>
              {/* <a className="font-bytestrone  space-x-4" href="/">
                News Room
              </a>
              <a className="font-bytestrone  space-x-4" href="/">
                Social Responsibility
              </a> */}
            </div>
          </div>
          <div className="w-full sm:w-3/6 flex flex-col space-y-3">
            <a className="font-bold font-bytestrone" href="/">
              CONTACT US
            </a>
            <div className="flex flex-col space-y-3 px-4">
              <a href="mailto:info@bytestrone.com" className="font-bytestrone flex-nowrap">
                <FontAwesomeIcon icon={faAt} /> info@bytestrone.com
              </a>
              {/* <a href="mail" className="font-bytestrone">
                <FontAwesomeIcon icon={faPhone} /> +91 XXX XXX XXXX
              </a> */}
            </div>
            <div className="w-full sm:w-2/6 pt-6 flex items-end mb-1">
              <div className=" flex flex-row lg:space-x-4  space-x-1 py-0 md:py-8">
                <div className="rounded-full sm:h-12 sm:w-12 sm:text-2xl h-8 w-8 text-xl border-solid bg-transparent  border-bytestrone-blue border-2 flex justify-center items-center cursor-pointer transform hover:scale-110">
                  <a
                    href="https://www.linkedin.com/company/bytestrone/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />{" "}
                  </a>
                </div>
                <div className="rounded-full sm:h-12 sm:w-12  sm:text-2xl h-8 w-8 text-xl border-solid bg-transparent  border-bytestrone-blue border-2 flex justify-center items-center cursor-pointer transform hover:scale-110">
                  <a
                    href="https://www.facebook.com/bytestrone"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </a>
                </div>
                <div className="rounded-full sm:h-12 sm:w-12  sm:text-2xl  h-8 w-8 text-xl border-solid bg-transparent  border-bytestrone-blue border-2 flex justify-center items-center cursor-pointer transform hover:scale-110">
                  <a
                    href="https://twitter.com/bytestrone"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </div>
                <div className="rounded-full sm:h-12 sm:w-12  sm:text-2xl h-8 w-8 text-xl border-solid bg-transparent  border-bytestrone-blue border-2 flex justify-center items-center cursor-pointer transform hover:scale-110">
                  <a
                    href="https://www.youtube.com/channel/UClezDvUFUMTz6h0efOmdrMw"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </div> 
                <div className="rounded-full sm:h-12 sm:w-12  sm:text-2xl h-8 w-8 text-xl border-solid bg-transparent  border-bytestrone-blue border-2 flex justify-center items-center cursor-pointer transform hover:scale-110">
                  <a
                    href="https://www.instagram.com/bytestrone"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full px-8 flex sm:flex-row flex-col-reverse  lg:absolute lg:bottom-10 ">
        <div className="w-full md:w-3/6 pt-2 font-bytestrone">
          <p>© 2021 Bytestrone. All rights reserved.</p>
        </div>
        <div className="w-full md:w-3/6 flex  md:flex-row flex-col lg:space-x-16 md:space-x-10">
          <div className="pt-2 font-bytestrone space-x-4">
            <AniLink to="/privacy-policy">Privacy Policy</AniLink>
          </div>
          <div className="pt-2 font-bytestrone space-x-4">
            <AniLink to="/terms-of-service">Terms of Service</AniLink>
          </div>
          <div className="pt-2 font-bytestrone space-x-4">
            <AniLink to="/cookie-policy">Cookie Policy</AniLink>
          </div>
          {/* <div className="pt-2 font-bytestrone">
            <p>Cookie Preference</p>
          </div> */}
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

const BackToTop: FunctionComponent = () => {
  const scrollY = useScrollPosition(60 /*fps*/);

  return scrollY > 50 ? (
    <div
      className="fixed z-30 bottom-5 flex justify-center items-center right-10 shadow-2xl "
      id="back_top"
    >
      <button
        style={{ zIndex: 1000 }}
        className="bg-bytestrone-green rounded-full  h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline border-white border-2 border-solid opacity-75"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <FontAwesomeIcon icon={faArrowUp} className="text-white" />
      </button>
    </div>
  ) : null;
};

export default Footer;