import './src/styles/global.css';
import React from "react";
import MainLayout from './src/layouts/mainLayout';
import {IntlProvider} from 'gatsby-plugin-react-intl';
export {wrapRootElement} from './src/utils/wrap-root-element'
export const onClientEntry =()=> {
    // window.addEventListener('load', () => {
    //   document.body.className = "bg-gray-100";
    // });
    //document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale='+(1/window.devicePixelRatio));
    if (!(`IntersectionObserver` in window)) {
      import(`intersection-observer`)
      //console.log(`# IntersectionObserver is polyfilled!`)
    }
    return;
  }



// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
    //console.log("new pathname", location.pathname)
    //console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}


// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
    //console.log(props)
    //import { useIntl } from "react-intl"
    return (
                <IntlProvider locale={props.pageContext.intl.language} messages={props.pageContext.intl.messages} defaultLocale="en">
                    <MainLayout {...props}>{element}</MainLayout>
                </IntlProvider>
            )
}


