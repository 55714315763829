import React, { FunctionComponent } from "react";
import ProgressBar from "react-scroll-progress-bar";
import NavBar from "../components/shared/nav";
import Footer from "../components/shared/footer";
import Seo from "../components/shared/seo";
import CookieConsent from "react-cookie-consent";
import FloatingSocialIcons from "../components/shared/floating-social-icons";
import useScrollAnimations from "../utils/animation";
import { useEffect } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const MainLayout: FunctionComponent<{ location: Location }> = ({
  children,
  location,
}) => {
  useEffect(() => {
    useScrollAnimations();
  }, []);

  return (
    <main className="snap-vertical">
      <Seo></Seo>
      <NavBar location={location}></NavBar>
      <div className="progress">
        <ProgressBar bgcolor={"#28b780"} thickness="1"/>
      </div>
      {children}
      <FloatingSocialIcons />
      <Footer></Footer>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        declineButtonText="I decline"
        enableDeclineButton={true}
        cookieName="gatsby-gdpr-google-tagmanager"
        expires={150}
        style={{ background: "#68488d" }}
        buttonStyle={{ background: "#28b780" }}
      >
        We use cookies to improve your browsing experience on our website, to
        show you personalized content and to analyze our website traffic, and to
        understand where our visitors are coming from. By browsing our website,
        you consent to our use of cookies. Additional details are available in
        our{" "}
        <AniLink className="text-blue-300" to="/cookie-policy">
          Cookie Policy
        </AniLink>
        .
      </CookieConsent>
    </main>
  );
};

export default MainLayout;
