
import React, { FunctionComponent, useRef } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { NavBarItems } from './navbarItem'
import { useStaticQuery,graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import { useEffect } from 'react'
//import { useScrollYPosition } from 'react-use-scroll-position';
import './style.css'


const Navbar:FunctionComponent<{location:Location}> = ({location})=> {

    //const scrollY = useScrollYPosition();
    //const background_class = scrollY > 60? "fixed": "";
    const background_class = '';
    const sliderRef = useRef<HTMLDivElement>(null);
    const burgerRef = useRef<HTMLButtonElement>(null);
    const isOpen = useRef<Boolean>(false);
    const intl = useIntl();
    const localeLink = intl.locale=='en'?"/":`/${intl.locale}`;

    const toggleSlider = ()=> {
        sliderRef.current?.classList.toggle('nav-slider-hidden');
        sliderRef.current?.classList.toggle('nav-slider');
        burgerRef.current?.classList.toggle('toggle');
        burgerRef.current?.classList.toggle('toggle-close');
        isOpen.current = !isOpen.current;
    }

    const handleOutsideClick =(evt)=> {
        if(!sliderRef.current?.contains(evt.target) && isOpen.current) {
            toggleSlider();
        }
    }

    const navbarItemsQuery = useStaticQuery(
            graphql`
            query NavItemsQuery {
                allNavbarItemsJson {
                    edges {
                        node {
                            title
                            link
                            id
                            isSelected
                        }
                    }
                }
            }
        `);
    
        
    const navbarItems = navbarItemsQuery.allNavbarItemsJson.edges.map((item)=>{
           return item.node
    });

 
    useEffect(()=>{
        //Listen for click outside the slider
        document.addEventListener("click",handleOutsideClick);
        return ()=>{
            document.removeEventListener("click",handleOutsideClick)
        }
    },[]);
    
    const onToggleClose = (e)=> {
        e.preventDefault();
        e.stopPropagation();
        toggleSlider();
    }
    

    return (
        <nav className={`fixed flex bg-white p-2  md:px-10  w-screen top-0 z-50 shadow-2xl  ${background_class}`} id="topBar">
            <AniLink cover direction="right" duration={1} bg="#28b780" to={localeLink} className="mr-4 inline-flex items-center flex-shrink-0 cursor-pointer">
                <div style={{fontSize:0}} className="hidden md:block"><StaticImage  src="../../../images/bytestrone-logo-blue.svg" placeholder="blurred"   alt="logo" height={37.12} width={200} /></div>
                <div style={{fontSize:0}} className="block md:hidden mx-5"><StaticImage src="../../../images/bytestrone-logo-blue.svg" placeholder="blurred"   alt="logo" height={27.84} width={150} /></div>
            </AniLink>

            <button  className="burger toggle-close" ref={burgerRef} onClick={onToggleClose} onKeyPress={(e)=>{
                if(e.key=='Enter' || e.key=='Spacebar' || e.key==' ') {
                    onToggleClose(e);
                }
            }}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </button>

             {/*Mobile*/} 
            <div ref={sliderRef} className="nav-slider-hidden">
                <div className="relative w-full h-40 bg-bytestrone-blue flex justify-center items-center">
                    {/* <StaticImage alt="logo" src="../../../images/logo.svg" className="h-40 filter blur-md"/> */}
                    
                    <AniLink id="nav-header" cover direction="right" duration={1} bg="#28b780" to={localeLink}  className="absolute w-20 m-auto focus:outline-none" >
                        <button  onClick={()=>{
                            toggleSlider();
                        }}><StaticImage alt="logo" src="../../../images/logo-e-mark-02.svg" className=""/></button>
                    </AniLink>
                    
                </div>
                <div className="flex flex-col p-5 h-full relative">
                    <NavBarItems  location={location} onNavbarClick={React.useCallback(toggleSlider,[])} navItems={navbarItems}/>
                    {/* <StaticImage alt="e-podlogo" src="../../../images/epod-logo-blue.svg" className="z-30 absolute w-14 right-6 bottom-44 filter grayscale"/> */}
                </div>
            </div>
            {/*Desktop*/}
            <div className="hidden top-navbar w-full lg:inline-flex lg:flex-grow lg:w-auto z-40" id="navigation">
                <div
                    className="lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start  flex flex-col lg:h-auto">
                    <NavBarItems location={location} navItems={navbarItems}/>
                </div>
            </div>

            {/*Mobile*/}
            {/* <div className="md:hidden absolute right-12 bottom-0 h-1  w-32 flex flex-row justify-around">
                <div className="transform -skew-x-12 bg-bytestrone-blue w-6  h-full"></div>
                <div className="transform -skew-x-12 bg-bytestrone-green  w-6  h-full"></div>
                <div className="transform -skew-x-12 bg-bytestrone-purple  w-6  h-full"></div>
                <div className="transform -skew-x-12 bg-bytestrone-red  w-6  h-full"></div>
            </div> */}
            {/*Desktop*/}
            {/* <div className="invisible md:visible absolute right-12 top-0 h-2  w-40 flex flex-row justify-around">
                <div className="transform -skew-x-12 bg-bytestrone-blue w-8  h-full"></div>
                <div className="transform -skew-x-12 bg-bytestrone-green  w-8  h-full"></div>
                <div className="transform -skew-x-12 bg-bytestrone-purple  w-8  h-full"></div>
                <div className="transform -skew-x-12 bg-bytestrone-red  w-8  h-full"></div>
            </div> */}
        </nav>
    )
};


export default Navbar;
  