
const useScrollAnimations = ():void=> {
    const callback = (entries):void=> {
                    entries.forEach(entry => {
                    const animationType = entry.target.dataset.animateType;
                    // Is the element in the viewport?
                    if (animationType && entry.isIntersecting) {
                        // Add the fadeIn class:
                        entry.target.classList.add(animationType);
                    } else {
                        // Otherwise remove the fadein class 
                        entry.target.classList.remove(animationType);
                    }
                });
            };


        const targets = document.querySelectorAll(".animate-scroll");
        const observer = new IntersectionObserver(callback);
        targets.forEach(function(target) {
            // Hide the element
            target.classList.add("opacity-0");
            // Add the element to the watcher
            observer.observe(target);
        });
    //return observer;
}

export default useScrollAnimations;

